import { useAuth } from '@auth/AuthProvider';
import Modal, { ModalButtonStyle } from '@components/Modal'
import NetworkController, { HTTPMethod, HTTPRequest } from '@data/controller/NetworkController';
import { useState } from 'react';

import './index.scss'
import { WorshipPlaylist } from '@data/interfaces/playlist.interface';

export default function AddPlaylistModal(props: {
  isOpen: boolean,
  setIsOpen: (isOpen: boolean) => void,
  onPlaylistAdded: (playlist: WorshipPlaylist) => void
}) {
  const auth = useAuth();

  const [adding, setAdding] = useState(false);
  const [playlistURL, setPlaylistURL] = useState<string>("");
  const [error, setError] = useState<string | null>(null);

  const createPlaylist = async (playlistURL: string) => {
    const res = await NetworkController.performRequest<{
      result: WorshipPlaylist
    }>(
      new HTTPRequest(HTTPMethod.POST, `/church/${auth.churchId}/library/worship/playlists`, {
        playlistURL
      }),
      auth.consumeRefreshToken
    )

    if (res.error) {
      throw Error(res.error);
    }

    if (res.body) {
      props.onPlaylistAdded(res.body.result);
      props.setIsOpen(false);
    } else {
      throw Error("Failed to create playlist. Please try again later.");
    }
  }

  return <Modal id="add-playlist-modal"
    isOpen={props.isOpen}
    headerTitle={`Add Playlist`}
    footerButtons={[
      {
        title: `Add`,
        style: ModalButtonStyle.FILLED,
        enabled: !adding && playlistURL.length > 0,
        onClick: async () => {
          setAdding(true);

          try {
            await createPlaylist(playlistURL);
          } catch (error: any) {
            if (error instanceof Error) {
              setError(error.message);
            } else {
              setError("An unknown error occurred. Please try again later.");
            }
          }

          setAdding(false);
        }
      },
      {
        title: `Cancel`,
        style: ModalButtonStyle.OUTLINE,
        enabled: !adding,
        onClick: () => {
          props.setIsOpen(false)
        }
      }
    ]}>
    {error && <p className="error">{error}</p>}

    <p>Please paste the URL of your playlist:</p>

    <input type="text" placeholder="Playlist URL" value={playlistURL} onChange={(e) => {
      setPlaylistURL(e.target.value)
    }} />
  </Modal>
}