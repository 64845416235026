import WorshipSong from '@data/interfaces/song.interface';
import './index.scss'
import { useCallback, useEffect, useState } from 'react';
import { useAuth } from '@auth/AuthProvider';
import NetworkController, { HTTPMethod, HTTPRequest } from '@data/controller/NetworkController';

import shareIcon from '@icon/share.svg'
import trashIcon from '@icon/trash.svg'
import plusIcon from '@icon/plus.svg'

import { useChurchDataProvider } from '@data/ChurchDataProvider';
import PageHeader from '@components/PageHeader';
import AddPlaylistModal from './Modal/AddPlaylistModal';
import { WorshipPlaylist } from '@data/interfaces/playlist.interface';
import DeletePlaylistModal from './Modal/DeletePlaylistModal';

interface Library {
  worshipPlaylists: WorshipPlaylist[];
}

export default function Playlists() {
  const auth = useAuth();
  const churchData = useChurchDataProvider();

  const [library, setLibrary] = useState<Library | null>(null);

  const [isAddPlaylistModalOpen, setIsAddPlaylistModalOpen] = useState(false);
  const [isDeletePlaylistModalOpen, setIsDeletePlaylistModalOpen] = useState(false);

  const [playlistToDelete, setPlaylistToDelete] = useState<WorshipPlaylist | null>(null);

  const fetchLibrary = useCallback(async () => {
    if (!auth.churchId) return; // haven't loaded yet

    const res = await NetworkController.performRequest<{
      result: Library
    }>(
      new HTTPRequest(HTTPMethod.GET, `/church/${auth.churchId}/library/worship`),
      auth.consumeRefreshToken
    )

    if (res.body) {
      setLibrary(res.body.result);
    } else {
      console.error(res)
    }
  }, [auth.churchId, auth.consumeRefreshToken])

  useEffect(() => {
    fetchLibrary()
  }, [fetchLibrary]);

  return <div className="playlists">
    <PageHeader title="Playlists"
      subtitle="Create playlists for your church to listen to."
      showBackButton={false}
      links={
        [
          {
            title: 'Add Playlist',
            onClick: () => {
              setIsAddPlaylistModalOpen(true);
            },
            icon: plusIcon
          }
        ]
      } />

    <AddPlaylistModal isOpen={isAddPlaylistModalOpen}
      setIsOpen={setIsAddPlaylistModalOpen}
      onPlaylistAdded={(playlist) => {
        setLibrary({
          worshipPlaylists: [...library?.worshipPlaylists || [], playlist]
        })
      }} />

    <DeletePlaylistModal isOpen={isDeletePlaylistModalOpen}
      setIsOpen={setIsDeletePlaylistModalOpen}
      playlist={playlistToDelete}
      onPlaylistRemoved={() => {
        if (!playlistToDelete) return;

        setLibrary({
          worshipPlaylists: library?.worshipPlaylists.filter(playlist => playlist.id !== playlistToDelete.id) || []
        })
      }} />

    <div className="playlists-list">
      {library?.worshipPlaylists?.sort((a, b) => (a.createdAt > b.createdAt) ? -1 : ((a.createdAt < b.createdAt) ? 1 : 0)).map(playlist => (
        <div key={playlist.id} className="playlist">
          <div className='background'>
            <div>
              <h2 className='title'>{playlist.title}</h2>
            </div>

            <div className='options'>
              <a href={`${process.env.REACT_APP_SALT_PLAYLISTS_URL}/${churchData.church?.id}/${playlist.id}`}
                target='_blank'
                rel="noopener noreferrer"
                title="Open Playlist Link">
                <img src={shareIcon} alt="Share" />
              </a>
              <button onClick={async (e) => {
                e.preventDefault();

                setPlaylistToDelete(playlist);
                setIsDeletePlaylistModalOpen(true);
              }}
                title="Delete Playlist">
                <img src={trashIcon} alt="Delete" />
              </button>
            </div>
          </div>

          <PlaylistDetail playlist={playlist} />
        </div>
      ))}
    </div>
  </div>
}

const PlaylistDetail = (props: {
  playlist: WorshipPlaylist
}) => {
  return <ul>
    {props.playlist.songs.map(song => (
      <li key={song.id} className='song'>
        <img src={song.albumArtURL} alt={song.songTitle} className='cover-art' />
        <div>
          <h1>{song.songTitle}</h1>
          <p>{song.artistName}</p>
        </div>
      </li>
    ))}
  </ul>
}