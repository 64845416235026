import { useAuth } from '@auth/AuthProvider';
import Modal, { ModalButtonStyle } from '@components/Modal'
import NetworkController, { HTTPMethod, HTTPRequest } from '@data/controller/NetworkController';
import { useState } from 'react';

import './index.scss'
import { WorshipPlaylist } from '@data/interfaces/playlist.interface';

export default function DeletePlaylistModal(props: {
  isOpen: boolean,
  setIsOpen: (isOpen: boolean) => void,
  playlist: WorshipPlaylist | null,
  onPlaylistRemoved: () => void
}) {
  const auth = useAuth();

  const [deleting, setAdding] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const deletePlaylist = async () => {
    const res = await NetworkController.performRequest<{
      result: WorshipPlaylist
    }>(
      new HTTPRequest(HTTPMethod.DELETE, `/church/${auth.churchId}/library/worship/playlists/${props.playlist?.id}`),
      auth.consumeRefreshToken
    )

    if (res.error) {
      throw Error(res.error);
    }

    if (res.body) {
      props.onPlaylistRemoved();
      props.setIsOpen(false);
    } else {
      throw Error("Failed to delete playlist. Please try again later.");
    }
  }

  if (props.playlist === null) return null;

  return <Modal id="delete-playlist-modal"
    isOpen={props.isOpen}
    headerTitle={`Delete Playlist`}
    footerButtons={[
      {
        title: `Delete`,
        style: ModalButtonStyle.FILLED,
        enabled: !deleting,
        onClick: async () => {
          setAdding(true);

          try {
            await deletePlaylist();
          } catch (error: any) {
            if (error instanceof Error) {
              setError(error.message);
            } else {
              setError("An unknown error occurred. Please try again later.");
            }
          }

          setAdding(false);
        }
      },
      {
        title: `Cancel`,
        style: ModalButtonStyle.OUTLINE,
        enabled: !deleting,
        onClick: () => {
          props.setIsOpen(false)
        }
      }
    ]}>
    {error && <p className="error">{error}</p>}
    <p>Are you sure you'd like to delete this playlist?</p>
  </Modal>
}